import request from "../utils/request";

//登录
export const login = data => {
  return request({
    method: 'POST',
    url: '/system/admin/adminLogin',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

  })
}