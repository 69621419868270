import { login } from '@/api/login.js'
import { getToken, setToken, removeToken } from '@/utils/auth'
export default {
  namespaced: true,
  state: {
    token: getToken(),
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    removeToken (state) {
      state.token = null
      removeToken()
    }
  },
  actions: {
    async login (context, data) {
      const res = await login(data)
      console.log(res);
      if (res.token) {

        context.commit('setToken', res.token)
        setToken(res.token)
      }
    },

    logout ({ commit }) {

      commit('removeToken')
    }
  }
}
