<template>
  <div>
    <el-container>

      <!-- 侧边栏导航  -->
      <el-aside width="200px">
        <!-- 侧边栏标题 -->
        <el-row> 铭智生科官网后台
        </el-row>

        <el-menu default-active="1" class="el-menu-fff" text-color="#4A71AF" background-color="#EDF1F7"
          :vue-router="true">
          <!-- 新闻管理 -->
          <el-menu-item index="1" @click="$router.push('/header')"> 首页</el-menu-item>
          <el-menu-item index="2" @click="$router.push('/core')">核心技术</el-menu-item>
          <el-menu-item index="3" @click="$router.push('/productcenter')">产品中心</el-menu-item>
          <el-menu-item index="4" @click="$router.push('/resolve')">解决方案</el-menu-item>
          <el-menu-item index="5" @click="$router.push('/honor')">荣誉资质</el-menu-item>
          <el-menu-item index="6" @click="$router.push('/patent')">专利报告</el-menu-item>
          <el-menu-item index="7" @click="$router.push('/aboutus')">关于我们</el-menu-item>
          <el-menu-item index="8" @click="$router.push('/news')">新闻活动</el-menu-item>
        </el-menu>
      </el-aside>

      <el-container>
        <el-container>
          <!-- 头部 -->
          <el-header>
            <div class="avatar-wrapper">
              <span class="name"> {{ name }} </span>
              <el-button class="layBtn" @click="logout">退出</el-button>
            </div>
          </el-header>
          <!-- 主体部分 -->
          <el-main>
            <router-view />
          </el-main>
        </el-container>
      </el-container>
    </el-container>

  </div>
</template>

<script>
export default {
  data () {
    return {
      name: 'Hi , admin'
    }
  },
  methods: {
    async logout () {
      await this.$store.dispatch('user/logout')
      this.$router.push('/')
    },
  },
  mounted () {
    if (window.name == "") {
      console.log("首次被加载");
      window.name = "isReload"; // 在首次进入页面时我们可以给window.name设置一个固定值 
    } else if (window.name == "isReload") {
      this.$router.push('/header')
      console.log("页面被刷新");
    }


  }
}
</script >

<style lang="scss" scoped>
.el-menu-fff {
  i {
    font-size: 16px;
    color: #4a71af;
  }
}

.el-aside {
  background: #edf1f7;
  height: 1080px;
  .el-row {
    padding-top: 12px;
    padding-left: 20%;
    display: inline-block;
    height: 65px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4a71af;
    line-height: 20px;
  }
  span {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
}
.el-menu-item {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}
.el-menu-item.is-active {
  background-color: #4a71af !important;
  color: #fff;
}
.el-menu-item-group {
  text-align: center;
}

.el-header {
  height: 45px !important;
  background: #4a71af;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #fff;

  width: 1720px;
  .avatar-wrapper {
    margin-top: 10px;
    padding-left: 1483px;
  }

  .name {
    vertical-align: middle;
    margin-right: 12px;
  }
}
.el-main {
  width: 1720px;
  padding: 0;
  background-color: #fff;
  color: #333;
  text-align: center;
  height: 1000px;
}

body > .el-container {
  margin-bottom: 40px;
}
.layBtn {
  width: 60px;
  height: 28px;
  background: #ffffff;
  border-radius: 14px;
  vertical-align: middle;
  ::v-deep span {
    position: relative;
    left: -25%;
    width: 28px;
    height: 28px;
    display: inline-block;
    text-align: center;
    vertical-align: top;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4a71af;
    line-height: 2px;
  }
}
</style>