import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout'
import store from '@/store'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
//路由路径
const routes = [
  {//首页重定向到标签修改页
    path: '/',
    redirect: '/login'
  },
  {//登录页面
    path: '/login',
    component: () => import('@/views/Login')

  },
  {//首页
    path: '/layout',
    component: Layout,
    children: [
      {//首页
        path: '/header',
        component: () => import('../views/Header')
      },
      {//核心技术
        path: '/core',
        component: () => import('../views/Core')
      },
      {//产品中心
        path: '/productcenter',
        component: () => import('../views/ProductCenter')
      },
      {//解决方案
        path: '/resolve',
        component: () => import('../views/Resolve')
      },
      {//荣誉资质
        path: '/honor',
        component: () => import('../views/Honor')
      },
      {//专利报告
        path: '/patent',
        component: () => import('../views/Patent')
      },
      {//关于我们
        path: '/aboutus',
        component: () => import('../views/AboutUs')
      },
      {//新闻活动
        path: '/news',
        component: () => import('../views/News')
      },
      {//标签修改页
        path: '/label',
        component: () => import('@/views/newsManage/components/labelSetting.vue')
      },
      {//新闻列表页
        path: '/newlist',
        component: () => import('@/views/newsManage/components/newsList.vue')
      },
      {//代理商管理页
        path: '/application',
        component: () => import('@/views/Application')
      },
      {//专利报告标签管理页面
        path: '/reporttype',
        component: () => import('@/views/report/reportType.vue')
      },
      {//专利报告列表
        path: '/reportlist',
        component: () => import('@/views/report/reportList.vue')
      }

    ]
  },

]

const router = new VueRouter({
  routes
})
//路由守卫
router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && !store.getters.token && to.path !== '/') next({ path: '/login' })
  else next()
})

export default router
