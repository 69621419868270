import axios from 'axios'
import qs from 'qs'
import store from '@/store'
// 虎哥测试地址
// const baseURL='http://192.168.8.23:8010'
//梦达测试地址
// const baseURL = 'http://192.168.8.29:8010'
// 线上地址
const baseURL = 'https://api.mz-biotech.com/'
const service = axios.create({
  baseURL,
  timeout: 5000,

})

// 请求拦截器
service.interceptors.request.use(
  config => {
    const token = store.getters.token
    if (token) {
      config.headers.Authorization = token
    }

    // console.log(config,'config')
    // 判断自定义请求类型：CustomRequestType，true：json类型 

    if (config.headers.CustomRequestType) {
      config.data
    } else {
      config.data = qs.stringify(config.data)// 转为formdata数据格式
    }
    // this.$message.success('登录成功')
    // return
    return config;
  }, error => {
    console.log(error);
    return Promise.reject();
  }
);
// 响应拦截器  
service.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return response.data.data;
    } else {
      Promise.reject();
    }
  },
  error => {
    console.log(error, 'error');
    return Promise.reject();
  }
);

export default service;


